.fade-in-out {
  animation: fadeInOut 2s infinite;

  /* display: none; */
}


@keyframes fadeInOut {
  0% { opacity: 0; }
  30% { opacity: 1; }
  70% { opacity: 1; }
  100% { opacity: 0; }
}
